import { CheckCircleIcon as UiCheckCircleIcon } from "@l2r-front/l2r-icons"
import { IconButton as UiIconButton, styled, Typography as UiTypography } from "@l2r-front/l2r-ui"


export const Typography = styled(UiTypography)(({ theme }) => ({
    marginTop: theme.spacing(12),
    textAlign: "center",
}))

export const IconButton = styled(UiIconButton)(({ theme }) => ({
    backgroundColor: theme.palette["cta-bg/cta-bg-primary"].main,
    borderRadius: theme.spacing(3),
    marginLeft: "auto",
    width: "fit-content",

    "&:hover": {
        backgroundColor: theme.palette["colors/vert logiroad/900"].main,
    },
}))

export const TotalCostContainer = styled("div")(({ theme }) => ({
    display: "flex",
    marginTop: theme.spacing(4),
    flexDirection: "row",
    alignItems: "center",
}))

export const StatusCostHeading = styled("div")(({theme}) => ({
    display: "flex",
    marginLeft: theme.spacing(2),
    gap: theme.spacing(2),
    justifyContent: "center",
    alignItems: "center",
}))

export const DoneTag = styled("div")(({theme}) => ({
    display: "flex",
    gap: theme.spacing(2),
    backgroundColor: theme.palette["objects/object-grey"].main,
    borderRadius: theme.spacing(3),
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1, 2, 1, 2),
}))

export const Header = styled("div")(({ theme }) => ({
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(5),
}))

export const TextWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
})

export const CheckCircleIcon = styled(UiCheckCircleIcon)({
    height: "12px",
    width: "12px",
})